import { Banner, BlockStack, Button, Text } from '@shopify/polaris';
import { CheckIcon, ChatIcon } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import { handleOpenSupportChat } from '../../../utils';

const ReviewBanner = ({ admin_review, email }) => {
  const [active, setActive] = useState(!admin_review);
  return (
    <>
      {
        active &&
        <Banner
          title='We’re reviewing your application'
          icon={CheckIcon}
          onDismiss={() => setActive(false)}
          tone='success'
        >
          <BlockStack gap={200}>
            <Text>
              Our team is currently reviewing your application.
              We’ll keep you updated via email at <b>{email}</b>. If you need any assistance, feel free to reach out to our support team.
            </Text>
            <div>
              <Button
                icon={ChatIcon}
                borderWidth="200"
                onClick={() => {
                  handleOpenSupportChat();
                }}
              >
                Chat with us
              </Button>
            </div>
          </BlockStack>
        </Banner>
      }
    </>
  );
};

export default ReviewBanner;