/* eslint-disable no-param-reassign */
/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';

const adminAxiosInstance = axios.create({
  baseURL: '/admin/:referrer_id', // Placeholder for dynamic ID
  // other configurations
});

  let csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  // Add a request interceptor to set the Authorization header before making the request
  adminAxiosInstance.interceptors.request.use(
  (config) => {
    // Modify config object to include Authorization header
    config.headers.Authorization = `Bearer ${window.sessionToken}`;
    config.headers['X-CSRF-TOKEN'] = csrf;

    return config;
  },
  (error) =>
    // Handle request error
    Promise.reject(error),

  );

const embedAxiosInstance = axios.create({
  baseURL: '/', // replace with your admin API base URL
});

embedAxiosInstance.interceptors.request.use(
  (config) => {
    // Modify config object to include Authorization header
    config.headers.Authorization = `Bearer ${window.sessionToken}`;
    config.headers['X-CSRF-TOKEN'] = csrf;

    return config;
  },
  (error) =>
    // Handle request error
    Promise.reject(error),

  );

export { adminAxiosInstance, embedAxiosInstance };
