import {
  useQuery,
} from '@tanstack/react-query';
import { getMethod } from "../../api/services";

const GET_ANALYTICS = '/partners/analytics';
const GET_MONTHLY_VISITORS = "/partners/monthly_visitors";

export function useFetchAnalytics({ axiosInstance, ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['analytics'],
    queryFn: () => getMethod(GET_ANALYTICS, axiosInstance),
    select: (response) => response.data,
    placeholder: [],
  });
}

export function useFetchMonthlyVisitors({ axiosInstance, ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['monthly-visitors'],
    queryFn: () => getMethod(GET_MONTHLY_VISITORS, axiosInstance),
    select: (response) => response.data.data,
    placeholder: [],
  });
}