import React from 'react';
import { AppProvider } from '@shopify/polaris';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes'; // Ensure this import path is correct
import enTranslations from '@shopify/polaris/locales/en.json';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { NavMenu } from '@shopify/app-bridge-react';
// import { ShopDetailsProvider } from './context/ShopContext';

const queryClient = new QueryClient();

const App = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider i18n={enTranslations}>
        <BrowserRouter>
          <AppRoutes {...props} />
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  );
};

export default App;
