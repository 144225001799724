import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';
import { postMethod, patchMethod, getMethod } from '../../api/services';
import axios from 'axios';

const SETTINGS = "/partners/settings_data";
const PASSWORD = "/referrer_auths/password";
const SIGN_OUT = "/referrer_auths/sign_out";
const ADMIN_SIGN_OUT = "/admins/sign_out";

let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const deleteMethod = (query) => axios.delete(query, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

export function useFetchSettingsData({ axiosInstance, ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['settings'],
    queryFn: () => getMethod(SETTINGS, axiosInstance,),
    select: (response) => response.data,
    placeholder: [],
  });
}

export function useUpdateSettings({axiosInstance, ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => postMethod(SETTINGS, postBody, axiosInstance)
  });
}

export function useUpdatePassword({axiosInstance, ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn: () => patchMethod(PASSWORD, axiosInstance),
  });
}

export function useSignOut({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn: () => deleteMethod(SIGN_OUT),
  });
}

export function useAdminSignOut({...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn: () => deleteMethod(ADMIN_SIGN_OUT),
  });
}