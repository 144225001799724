import {
  useMutation,
} from '@tanstack/react-query';
import axios from 'axios';

const CREATE_PARTNER = '/partners/create';

export function useCreatePartner({...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => axios.post(CREATE_PARTNER, postBody),
  });
}