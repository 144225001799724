import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';
import { getMethod, postMethod } from '../../api/services';

const PAYOUTS = "/partners/payouts_data";
const PAYOUT_EMAIL = "/partners/payout_email";

export function usePayoutEmail({ axiosInstance, ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => postMethod(PAYOUT_EMAIL, postBody, axiosInstance),
  });
}

export function useFetchPayouts({axiosInstance, ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['payouts'],
    queryFn: () => getMethod(PAYOUTS, axiosInstance),
    select: (response) => response.data,
    placeholder: [],
  });
}