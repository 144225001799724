import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { adminAxiosInstance, embedAxiosInstance } from '../api/axios';

const AxiosContext = createContext(null);

export const useAxiosInstance = () => {
  return useContext(AxiosContext);
};

export const AxiosProvider = ({ children, layout, referrer_id }) => {
  let axiosInstance;

  if (layout === 'admin') {
    axiosInstance = adminAxiosInstance;
    axiosInstance.defaults.baseURL = `/admin/${referrer_id}`;
  } else {
    axiosInstance = embedAxiosInstance;
  }

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};

AxiosProvider.propTypes = {
  layout: PropTypes.string.isRequired,
  referrer_id: PropTypes.number,
  children: PropTypes.element.isRequired,
};
