import { Frame, Toast } from "@shopify/polaris";
import React from "react";
import PropTypes from "prop-types";
import * as styles from "./index.module.scss";

const ToastMessage = ({ isActive, content, onDismiss }) => {
  return (
    isActive ? (
      <div
        className={styles.frames}
      >
        <Frame>
          <Toast
            content={content}
            onDismiss={onDismiss}
          />
        </Frame>
      </div>
    ) : null
  );
};

ToastMessage.propTypes = {
  isActive: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default ToastMessage;