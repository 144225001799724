import {
  useQuery,
} from '@tanstack/react-query';
import { getMethod } from '../../api/services';

const VISITORS = "/partners/visitors_data";

export function useFetchVisitors({axiosInstance, ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['visitors'],
    queryFn: () => getMethod(VISITORS, axiosInstance),
    select: (response) => response.data,
    placeholder: [],
  });
}