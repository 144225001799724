import { BlockStack, Card, Select, Text, TextField } from '@shopify/polaris';
import React from 'react';
import { getData } from 'country-list';
import { useLocation } from 'react-router-dom';

const Account = ({ fields }) => {
  const { pathname } = useLocation();
  const isAdmin = pathname.includes("admin");

  // Get the list of countries
  const countryOptions = [
    { label: 'Select country', value: '' }, // Placeholder option
    ...getData().map((country) => ({
      label: country.name,
      value: country.code,
    })),
  ];

  return (
    <Card>
      <BlockStack gap={200}>
        <BlockStack>
          <Select
            label="Country or region"
            options={countryOptions}
            disabled={isAdmin}
            {...fields.country}
          />
        </BlockStack>
        <BlockStack gap={200}>
          <Text>Paypal email</Text>
          <TextField
            {...fields.paypal_email}
            disabled={isAdmin}
          />
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default Account;
