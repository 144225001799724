import { Button, InlineStack, Text, useBreakpoints } from "@shopify/polaris";
import { ArrowLeftIcon } from "@shopify/polaris-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
function PageHeader(props) {
  const { title, url, marginLeft = 0, badge, onClick } = props;
  const navigate = useNavigate();
  const { smDown } = useBreakpoints();
  const handleNavigation = () => {
    if (typeof onClick !== "undefined" && !url) {
      onClick();
    } else {
      navigate(url);
    }

  };

  return (
    <div style={{ marginLeft: marginLeft }}>
      <InlineStack blockAlign='center' align="start" gap={"200"} wrap={smDown}>
        {url || typeof onClick !== "undefined" ? (
          <Button
            variant="plain"
            icon={ArrowLeftIcon}
            onClick={handleNavigation}
          ></Button>
        ) : (
          ""
        )}
        <Text as="h2" breakWord variant="headingLg">
          {title}
        </Text>
        {badge ? badge : null}
      </InlineStack>
    </div>
  );
}

export default PageHeader;

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  marginLeft: PropTypes.number,
  badge: PropTypes.element,
  onClick: PropTypes.func,
};