import React from "react";
import { Card, BlockStack, Text, useBreakpoints } from "@shopify/polaris";
import { useSearchParams } from "react-router-dom";
import * as styles from "./index.module.scss";
import PropTypes from "prop-types";

const Body = ({ email }) => {
  return (
    <Card>
      <BlockStack
        gap={400}
        inlineAlign="center"
      >
        <br />
        <div>
          <img src="https://cdn.starapps.studio/v2/apps/vsk/starapps/swatches/Print/check.png"
            alt="check"
            className={styles.imageStyle}
          />
        </div>
        <Text
          alignment="center"
          variant="heading2xl"
        >Thank You!</Text>
        <Text
          alignment="center"
        >Your application has been submitted for review. Please verify your email at <b>{email}</b> to complete the application.</Text>
        <br />
      </BlockStack>
    </Card>
  );
};

const FormSubmitted = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || 'your email';
  const { smUp } = useBreakpoints();

  return (
    smUp ? (
      <div className={styles.container}>
        <Body email={email} />
      </div>
    ) : (
      <Body email={email} />
    )
  );

};

Body.propTypes = {
  email: PropTypes.any,
};

export default FormSubmitted;
