import React from 'react';
import { BlockStack, Card, Select, Text, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { businessTypeOptions } from '../helper';
import { useLocation } from 'react-router-dom';

const Business = ({ fields, referralPlans }) => {
  const { pathname } = useLocation();
  const isAdmin = pathname.includes("admin");
  return (
    <Card>
      <BlockStack gap={200}>
        <BlockStack gap={200}>
          <Text>Company Name</Text>
          <TextField
            disabled={isAdmin}
            {...fields.company_name}
          />
        </BlockStack>
        <BlockStack gap={200}>
          <Select
            label="Business type"
            disabled={isAdmin}
            options={businessTypeOptions}
            {...fields.business_profile}
          />
        </BlockStack>
        <BlockStack gap={200}>
          <Text>Website</Text>
          <TextField
            disabled={isAdmin}
            {...fields.url}
          />
        </BlockStack>
        <BlockStack gap={200}>
          <Text>Affiliate perks</Text>
          {
            referralPlans.map((plan) => (
              <TextField
                disabled
                value={`${plan.referral_code}: ${plan.affiliate_fee}%`}
                key={plan.referral_code}
              />
            ))
          }

        </BlockStack>
      </BlockStack>
    </Card>
  );
};

Business.propTypes = {
  fields: PropTypes.any,
  referralPlans: PropTypes.any,
};

export default Business;