/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Frame, Page, Popover, ActionList, Text, Icon, useBreakpoints } from '@shopify/polaris';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Sidebar from './Sidebar';
import * as styles from './Sidebar.module.scss';
import { useAdminSignOut, useSignOut } from '../settings/api';
import * as style from './index.module.scss';
import { ExitIcon, MenuVerticalIcon } from '@shopify/polaris-icons';
import MobileMenu from './MobileMenu';
import { AxiosProvider } from '../../context/AxiosContext';

const Layout = ({ admin_review, email, referrer_id }) => {
  const navigate = useNavigate();
  const { smDown } = useBreakpoints();
  const { pathname } = useLocation();
  const route = pathname.split('/').pop();
  const fullWidthPages = ['dashboard', 'referrals', 'visitors'];
  const isFullWidth = fullWidthPages.includes(route);
  const isAdmin = pathname.includes("admin");

  const { mutate } = useSignOut({
    onSuccess: () => {
      // Reload the page
      window.location.reload();
    },
    onError: () => {
      window.location.reload();
    }
  });

  const { mutate: adminMutate } = useAdminSignOut({
    onSuccess: () => {
      window.location.href = "/referrers";
    },
    onError: () => {
      window.location.href = "/referrers";
    }
  });

  const [popoverActive, setPopoverActive] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const togglePopoverActive = () => setPopoverActive((prev) => !prev);
  const toggleMobileMenu = () => setMobileMenu((prev) => !prev);

  const activator = (
    <div className={style.activator} onClick={() => togglePopoverActive()}>
      <Text variation="strong">{email.charAt(0).toUpperCase()}</Text>
    </div>
  );

  const mobileActivator = (
    <div onClick={toggleMobileMenu}>
      <Icon
        source={MenuVerticalIcon}
        tone="base"
      />
    </div>
  );

  const handleLogout = () => {
    // Handle logout logic here
    isAdmin ? adminMutate() : mutate();
    // Optionally close the popover after logout
    setPopoverActive(false);
  };

  return (
    <AxiosProvider layout={isAdmin ? 'admin' : 'referrer'} referrer_id={referrer_id}>
      <div className={styles.container}>
        <header
          className={style.header}
        >
          <div className={style.imageContainer}>
            <img
              src="https://cdn.starapps.studio/v2/apps/vsk/starapps/swatches/Print/logov2.png"
              alt="Header Logo"
              className={style.images}
              onClick={() => navigate("dashboard")}
            />
            {
              smDown &&
              <MobileMenu
                activator={mobileActivator}
                active={mobileMenu}
                toggleActive={toggleMobileMenu}
                adminReview={admin_review}
              />
            }
          </div>
          <div
            className={style.marginForLogout}
          >
            <Popover
              active={popoverActive}
              activator={activator}
              onClose={() => togglePopoverActive}
              autofocusTarget="first-node"
            >
              <ActionList
                actionRole="menuitem"
                items={[
                  { content: 'Logout', onAction: handleLogout, icon: ExitIcon },
                ]}
              />
            </Popover>
          </div>
        </header>
        <Frame navigation={<Sidebar admin_review={admin_review} />}>
          <Page fullWidth={isFullWidth}>
            <Outlet />
          </Page>
        </Frame>
      </div>
    </AxiosProvider >

  );
};

Layout.propTypes = {
  admin_review: PropTypes.bool,
  email: PropTypes.string,
  referrer_id: PropTypes.number
};

export default Layout;
