export const businessTypeOptions = [
  { label: 'Select business type', value: 'select' },
  { label: 'Shopify expert', value: 'shopify_expert' },
  { label: 'App developer', value: 'app_developer' },
  { label: 'Theme developer', value: 'theme_developer' },
  { label: 'e-commerce advisor', value: 'ecommerce_advisor' },
  { label: 'Content creator', value: 'content_creator' },
  { label: 'Other', value: 'other' },
];

export const promoteOptions = [
  { label: 'Select promotion', value: 'select' },
  { label: 'Social media', value: 'social_media' },
  { label: 'Blog', value: 'blog' },
  { label: 'Recommend to clients', value: 'recommend_to_clients' },
  { label: 'Use in my own store', value: 'use_in_my_own_store' },
  { label: 'Paid ads', value: 'paid_ads' },
  { label: 'Email/Newsletter', value: 'email_newsletter' },
  { label: 'Other', value: 'other' },
];

export const perksOptions = [
  { label: "20% revenue share for myself sounds good", value: "affiliate_fee" },
  { label: "I don't need any perk, you may give 20% discount to the store", value: "merchant_discount" },
];