import React, { useEffect } from "react";
import { useForm, useField, notEmpty } from "@shopify/react-form";
import { BlockStack, Button, Card, Form, InlineGrid, InlineStack, Select, Text, TextField, useBreakpoints } from "@shopify/polaris";
import { useCreatePartner } from "./api";
import { useNavigate } from "react-router-dom";
import * as styles from "./index.module.scss";
import { businessTypeOptions, promoteOptions, perksOptions } from "../helper";


const NewUserForm = () => {
  const navigate = useNavigate();
  const { smUp, smDown } = useBreakpoints();

  const {
    mutate,
    isLoading,
  } = useCreatePartner({
    onSuccess: (response) => {
      response.data.errors.forEach((error) => {
        const messages = error.split(':');
        if (error.includes("Email")) {
          fields.email.setError(messages[messages.length - 1]);
        }
      });
      if (response.data.status == 200) {
        reset();
        navigate(`/partners/form-submit?email=${fields.email.value}`);
      }
    },
  });
  const { fields, submit, dirty, reset } = useForm({
    // initial values
    fields: {
      first_name: useField({
        value: "",
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: "",
        validates: [notEmpty("Last name is required")],
      }),
      email: useField({
        value: "",
        validates: [
          notEmpty("Email is required"),
          value => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) return 'Please enter a valid email address';
          }
        ],
      }),
      company_name: useField({
        value: "",
        validates: [notEmpty("Business name is required")],
      }),
      url: useField({
        value: "",
        validates: [notEmpty("Website is required")],
      }),
      business_profile: useField({
        value: businessTypeOptions[0].value,
        validates: [
          notEmpty("Business type is required"),
          (value) => {
            if (value === "select") {
              return "Business type is required";
            }
            return null;
          }
        ],
      }),
      promotion_type: useField({
        value: promoteOptions[0].value,
        validates: [
          notEmpty("Promotion type is required"),
          (value) => {
            if (value === "select") {
              return "Promotion type is required";
            }
            return null;
          }
        ],
      }),
      discount_or_fee: useField({
        value: perksOptions[0].value,
        validates: [
          notEmpty("Perks is required"),
          (value) => {
            if (value === "select") {
              return "Perks is required";
            }
            return null;
          }
        ],
      }),
    },
    // submit function
    onSubmit: async (form) => {
      mutate(form);
      return { status: "success" };
    },
  });

  const handleSignIn = () => {
    window.location.href = '/referrer_auths/sign_in';
  };

  useEffect(() => {
    if (smDown) {
      const thElements = document.querySelectorAll('.form-container form div');
      if (thElements.length > 0) {
        thElements[0].style.removeProperty('--pc-shadow-bevel-content-xs');
      }
    }
  }, [smDown]);

  return (
    <div className="form-container">
      <Form onSubmit={submit}>
        <Card>
          <div>
            <img
              className={styles.imageStyle}
              src="https://cdn.starapps.studio/v2/apps/vsk/starapps/swatches/Print/logov2.png" alt="logo" />
          </div>
          <div
            className={styles.formMargin}
          >
            <BlockStack gap={400}>
              <Text
                variant="heading2xl"
              >Become a partner, sign up</Text>
              <InlineStack gap={100}>
                <Text>Already have an account?</Text>
                <Button onClick={handleSignIn} variant="plain">Sign in</Button>
              </InlineStack>
              <InlineGrid columns={smUp ? 2 : 1} gap={400}>
                <TextField label="First name" error={fields.first_name.error} {...fields.first_name} />
                <TextField label="Last name" error={fields.last_name.error} {...fields.last_name} />
              </InlineGrid>
              <TextField label="Email" type="email" error={fields.email.error} {...fields.email} />
              <TextField label="Business name" error={fields.company_name.error} {...fields.company_name} />
              <TextField label="Business website" error={fields.url.error} {...fields.url} />
              <Select
                label="Business type"
                options={businessTypeOptions}
                error={fields.business_profile.error}
                {...fields.business_profile}
              />
              <Select
                label="How will you promote the app?"
                options={promoteOptions}
                {...fields.promotion_type}
              />
              <Select
                label="How would you like to receive our perks?"
                options={perksOptions}
                {...fields.discount_or_fee}
              />
              <Button variant="primary" submit loading={isLoading} disabled={!dirty || isLoading}>Submit application</Button>
            </BlockStack>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default NewUserForm;
