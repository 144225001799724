/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BlockStack, Button, Card, InlineStack, Modal, Select, Spinner, TextField, useBreakpoints } from "@shopify/polaris";
import React, { useState } from "react";
import PageHeader from "../page_header";
import { useCreateNewPlan, useFetchAffiliateLinks } from "./api";
import { useQueryClient } from "@tanstack/react-query";
import Table from "./Table";
import TableHeader from "./TableHeader";
import ToastMessage from "../common/ToastMessage";
import { useAxiosInstance } from '../../context/AxiosContext';

const options = [
  { label: "20% revenue share for myself sounds good", value: "affiliate_fee" },
  { label: "I don't need any perk, you may give 20% discount to the store", value: "merchant_discount" },
];

function AffiliateLinks() {
  const axiosInstance = useAxiosInstance();
  const { smUp, smDown } = useBreakpoints();
  const queryClient = useQueryClient();
  const { data, isLoading: affiliateLoading } = useFetchAffiliateLinks({ axiosInstance });
  const { mutate, isLoading } = useCreateNewPlan({
    axiosInstance,
    onSuccess: (response) => {
      setReferralCode("");
      setActive(false);
      if (response?.data?.status == 401) {
        setToast({
          active: true,
          content: response?.data?.message
        });
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["affiliate-links"] });
      setToast({
        active: true,
        content: "New links created"
      });
    }
  });
  const [active, setActive] = useState(false);
  const [toast, setToast] = useState({
    active: false,
    content: ""
  });
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleAddLink = () => {
    if (!referralCode.trim()) {
      setError('Referral code cannot be blank');
      return;
    }
    mutate({
      referral_code: referralCode.split(" ").join("-"),
      discount_or_fee: selectedOption
    });
    setError('');
  };

  const handleToast = () => {
    setToast({
      active: false,
      content: ""
    });
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <BlockStack gap={400}>
      <InlineStack
        align="space-between"
      >
        <PageHeader title="Affiliate links" />
        <Button
          variant="primary"
          onClick={() => setActive(true)}
        >Add link</Button>
      </InlineStack>
      {
        affiliateLoading ? (
          <Spinner />
        ) : (
          <>
            {data?.data.map(affiliate => (
              <Card key={affiliate.id}>
                <BlockStack gap={200}>
                  <TableHeader affiliate={affiliate} />
                  <Table affiliate={affiliate} />
                </BlockStack>
              </Card>
            ))}
          </>
        )
      }

      <Modal
        open={active}
        onClose={() => setActive(false)}
        title="Add new link"
        footer={smDown && (
          <InlineStack align="start">
            <Button
              variant="primary"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleAddLink}
            >
              Submit
            </Button>
          </InlineStack>
        )}
        primaryAction={
          smUp && {
            content: "Submit",
            onAction: handleAddLink,
            disabled: isLoading,
            loading: isLoading
          }
        }
      >
        <Modal.Section>
          <BlockStack gap={200}>
            <TextField
              label="Referral code"
              value={referralCode}
              onChange={(value) => setReferralCode(value)}
              error={error}
            />
            <Select
              label="How would you like to receive our perks?"
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
            />
          </BlockStack>
        </Modal.Section>
      </Modal>
      {toast.active && <ToastMessage isActive={toast.active} content={toast.content} onDismiss={handleToast} />}
    </BlockStack>
  );
}

export default AffiliateLinks;
