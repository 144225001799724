import React from "react";
import { BlockStack, Link, Text, useBreakpoints, } from "@shopify/polaris";
import * as styles from "./Sidebar.module.scss";
import check from "../../../assets/check.svg";

const Sidebar = () => {
  const { smUp } = useBreakpoints();
  return (
    <div className={styles.container}>
      <BlockStack gap={smUp ? 400 : 0}>
        <div className={styles.headContainer}>
          <div className={styles.heading}>
            Build world-class shopping experiences for your client and earn <b>20%</b> revenue share on referrals.
          </div>
          <div
            className={styles.body}>
            Our largest partner earned <span className={styles.color}><b>$10K+</b></span> in 2023
          </div>
        </div>

        <div className={styles.listContainer}>
          <div className={styles.flex}>
            <div>
              <img src={check} alt="" />
            </div>
            <div>Trusted by over <b> 25000+ Shopify stores</b></div>
          </div>
          <div className={styles.flex}>
            <div>
              <img src={check} alt="" />
            </div>
            <div>Powering over <b> 1,000+ Shopify Plus stores</b></div>
          </div>
          <div className={styles.flex}>
            <div>
              <img src={check} alt="" />
            </div>
            <div><b>4.9 star rating </b> by thousands of satisfied brands</div>
          </div>
        </div>

        <div className={styles.link}>
          <Text>Go to{' '}
            <Link
              removeUnderline
              url="https://apps.shopify.com/partners/starapps-studio"
              target="_blank"
            >
              shopify app store</Link>
          </Text>
        </div>
      </BlockStack>
    </div>
  );
};


Sidebar.propTypes = {
  // shop: PropTypes.object.isRequired,
};

export default Sidebar;
