import { Icon } from '@shopify/polaris';
import React from 'react';
import { HomeIcon, SettingsIcon, ChatReferralIcon, TeamIcon, AffiliateIcon, ReceiptDollarIcon, QuestionCircleIcon, LockIcon } from '@shopify/polaris-icons';
import { Link, useLocation } from 'react-router-dom';
import * as styles from './Sidebar.module.scss';

const allowedUrls = ["dashboard", "help", "settings"];
const findLink = (currentUrl, admin_review) => {
  if (allowedUrls.includes(currentUrl)) return currentUrl;

  if (!admin_review) return "dashboard";

  return currentUrl;
};

const Sidebar = ({ admin_review }) => {
  const location = useLocation(); // Get the current location pathname
  const pathname = location.pathname;

  const pathSegments = pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];


  const routes = [
    {
      id: 1,
      url: 'dashboard',
      label: 'Dashboard',
      selected: false,
      icon: HomeIcon,
      subNavigationItems: [],
    },
    {
      id: 2,
      url: 'visitors',
      label: 'Visitors',
      selected: false,
      icon: TeamIcon,
      subNavigationItems: [],
    },
    {
      id: 3,
      url: 'referrals',
      label: 'Referrals',
      selected: false,
      icon: ChatReferralIcon,
      subNavigationItems: [],
    },
    {
      id: 4,
      url: 'affiliate-links',
      label: 'Affiliate links',
      selected: false,
      icon: AffiliateIcon,
      subNavigationItems: [],
    },
    {
      id: 5,
      url: 'payouts',
      label: 'Payouts',
      selected: false,
      icon: ReceiptDollarIcon,
      subNavigationItems: [],
    },
    {
      id: 6,
      url: 'settings',
      label: 'Settings',
      icon: SettingsIcon,
      selected: false,
      subNavigationItems: [],
    },
    {
      id: 7,
      url: 'help',
      label: 'Help',
      selected: false,
      icon: QuestionCircleIcon,
      subNavigationItems: [],
    },
  ];

  const findSelectedItem = (routes) => {
    let selectedRoute = {};
    let selectedSubRoute = {};

    routes.forEach((route) => {
      if (route.url === lastSegment) {
        selectedRoute = route;

      } else if (route.subNavigationItems) {
        route.subNavigationItems.forEach((subRoute) => {
          if (subRoute.url === route.url + '/' + lastSegment) {
            selectedRoute = route;
            selectedSubRoute = subRoute;
          }
        });
      }
    });

    return { selectedRoute, selectedSubRoute };
  };

  const { selectedRoute, selectedSubRoute } = findSelectedItem(routes);

  return (
    <div
      className={styles.container}
    >
      <nav className="Polaris-Navigation">
        <div
          className="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal Polaris-Scrollable--scrollbarWidthThin"
          data-polaris-scrollable="true"
        >
          <ul className="Polaris-Navigation__Section">
            {routes.map((route) => (
              <li key={route.id} className={`Polaris-Navigation__ListItem ${styles.bottomPadding}`}>
                <div className="Polaris-Navigation__ItemWrapper">
                  <div
                    className={`Polaris-Navigation__ItemInnerWrapper ${route.id === selectedRoute.id
                      ? "Polaris-Navigation__ItemInnerWrapper--selected"
                      : ""
                      }`}
                  >
                    <Link to={findLink(route.url, admin_review)} className="Polaris-Navigation__Item">
                      <div className="Polaris-Navigation__Icon">
                        <Icon source={route.icon} tone="base" />
                      </div>
                      <span className="Polaris-Navigation__Text">
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium">
                          {route.label}
                        </span>
                      </span>
                      {
                        !admin_review && !admin_review && !allowedUrls.includes(route.url) &&
                        <div className="Polaris-Navigation__Icon">
                          <Icon source={LockIcon} tone="subdued" />
                        </div>
                      }
                    </Link>
                  </div>
                </div>
                {route.subNavigationItems.length > 0 ? (
                  <div className="Polaris-Navigation__SecondaryNavigation Polaris-Navigation__SecondaryNavigationOpen">
                    <div
                      className={`Polaris-Collapsible ${styles.maxHeight}`}
                      aria-hidden="false"
                    >
                      <ul className="Polaris-Navigation__List">
                        {route.subNavigationItems.map((subRoute) => (
                          <li key={subRoute.id} className="Polaris-Navigation__ListItem">
                            <div className="Polaris-Navigation__ItemWrapper">
                              <div
                                className={`Polaris-Navigation__ItemInnerWrapper ${subRoute.id === selectedSubRoute.id
                                  ? "Polaris-Navigation__ItemInnerWrapper--selected"
                                  : ""
                                  }`}>
                                <Link to={subRoute.url} className="Polaris-Navigation__Item Polaris-Navigation__Item--line">
                                  <span className="Polaris-Navigation__Text">
                                    <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular Polaris-Text--subdued">
                                      {subRoute.label}
                                    </span>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;