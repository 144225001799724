import React from 'react';
import { BlockStack, Card, DataTable, Text, Badge, InlineStack, Link, useBreakpoints } from '@shopify/polaris';
import { useFetchAllReferrals } from './api';
import PageHeader from '../page_header';
import { useAxiosInstance } from '../../context/AxiosContext';

const AFFILIATE_LINK_PREFIX = "https://partners.starapps.studio/";

const Referrals = () => {
  const axiosInstance = useAxiosInstance();
  const { smUp } = useBreakpoints();
  const { data, isLoading } = useFetchAllReferrals({ axiosInstance });

  // Map data to rows or use an empty array if loading or no data
  let rows = isLoading || !data ? [] : data?.data?.map((row, index) => {
    const subscriptionText = row[4]; // First half of the subscription
    const subscriptionSubdued = `/${row[5]}`; // Second half to be subdued
    const planStatus = row[6];
    let badgeTone;

    // Determine badge tone based on plan status
    switch (planStatus) {
      case 'Trial':
        badgeTone = 'attention';
        break;
      case 'Active':
      case 'Free':
        badgeTone = 'success';
        break;
      case 'Paused':
        badgeTone = 'warning';
        break;
      default:
        badgeTone = 'default'; // Fallback tone
    }

    return [
      <Link
        key={`${row[0]}_${index}`}
        url={`https://${row[1]}`}
        target="_blank"
        monochrome
      >
        {row[0]}
      </Link>,
      row[2],
      smUp && <InlineStack key={`${subscriptionText}_${index}`} >
        <Text key="subscription">
          {subscriptionText}
        </Text>
        <Text variant='subdued'>{subscriptionSubdued}</Text>
      </InlineStack>
      ,
      smUp && <Badge tone={badgeTone} key={`${planStatus}_${index}`}>{planStatus}</Badge>,
      smUp && (row[3]?.includes(AFFILIATE_LINK_PREFIX) ? row[3].split(AFFILIATE_LINK_PREFIX)[1] : row[3]),
      smUp && row[7],
      row[8],
    ];
  });

  return (
    <BlockStack gap={400}>
      <PageHeader title='Referrals' />
      <Card padding={0}>
        <DataTable
          stickyHeader
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
            'text',
            'numeric',
            'numeric',
          ]}
          headings={[
            <Text fontWeight="bold" key={1}>Store</Text>,
            <Text fontWeight="bold" key={2}>App</Text>,
            smUp && <Text fontWeight="bold" key={3}>Subscription</Text>,
            smUp && <Text fontWeight="bold" key={4}>Plan status</Text>,
            smUp && <Text fontWeight="bold" key={5}>Affiliate link</Text>,
            smUp && <Text fontWeight="bold" key={6}>Revenue</Text>,
            <Text fontWeight="bold" key={7}>Partner earnings</Text>, // Fixed duplicate key
          ]}
          rows={rows}
          footerContent={rows.length === 0 ? <Text>Your referrals will be displayed here</Text> : null} // Optional footer message
        />
      </Card>
    </BlockStack>
  );
};

export default Referrals;
