import React from "react";
import { BlockStack, InlineStack, Text } from "@shopify/polaris";
import PropTypes from "prop-types";

export default function TableHeader({ affiliate }) {
  return (
    <InlineStack
      align="space-between"
    >
      <BlockStack>
        <InlineStack align="start" gap={"100"}>
          <Text fontWeight="bold">Referral code:</Text>
          <Text>{affiliate?.referral_code}</Text>
        </InlineStack>
        {affiliate?.affiliate_fee > 0 && <Text tone="subdued">{affiliate?.affiliate_fee}% revenue share. Lifetime</Text>}
        {affiliate?.merchant_discount > 0 && <Text tone="subdued">{affiliate?.merchant_discount}% merchant discount. Lifetime</Text>}
      </BlockStack>
      <BlockStack>
        <InlineStack align="start" gap={"100"}>
          <Text fontWeight="bold">Total earning:</Text>
          <Text>${Number(affiliate?.amount || 0).toFixed(2)}</Text>
        </InlineStack>
      </BlockStack>
    </InlineStack>
  );
}

TableHeader.propTypes = {
  affiliate: PropTypes.any,
};