import React from 'react';
import { BlockStack, Card, Text, TextField } from '@shopify/polaris';
import PropTypes from 'prop-types';

const Email = ({ email }) => {
  return (
    <Card>
      <BlockStack gap="200">
        <Text>Your email</Text>
        <TextField
          value={email}
          disabled
        />
        <div>
          {/* <Button onClick={handleClick}>Change password</Button> */}
        </div>
      </BlockStack>
    </Card>
  );
};

Email.propTypes = {
  email: PropTypes.string,
};

export default Email;
