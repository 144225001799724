// extracted by mini-css-extract-plugin
var _1 = "nCfxnqcRLscDWtBkDOov";
var _2 = "PUKhwRDksHOWOnfz9DLn";
var _3 = "SDdW6wY_mYGmNb3iQHge";
var _4 = "Cv_s14v4lBti_zZDBOyd";
var _5 = "KWpNzDLlYqqSBfOxDNJ_";
var _6 = "_Ij5VqyjAN2exaoRsFYA";
var _7 = "XNwg3Qe_M9S0CBYj9TJg";
var _8 = "ATBof5N5CwF9q3c0gkdW";
export { _1 as "body", _2 as "color", _3 as "container", _4 as "flex", _5 as "headContainer", _6 as "heading", _7 as "link", _8 as "listContainer" }
