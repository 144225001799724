import {
  useQuery,
} from '@tanstack/react-query';
import { getMethod } from '../../api/services';

const GET_ANALYTICS = '/partners/all_referrals';

export function useFetchAllReferrals({axiosInstance, ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['all-referrals'],
    queryFn: () => getMethod(GET_ANALYTICS, axiosInstance),
    select: (response) => response.data,
    placeholder: [],
  });
}