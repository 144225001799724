import React from 'react';
import ReviewBanner from './ReviewBanner';
import Analytics from './Analytics';
import { BlockStack } from '@shopify/polaris';
import Graph from './Graph';
const Dashboard = (props) => {
  return (
    <BlockStack gap={400}>
      <ReviewBanner {...props} />
      <Analytics />
      <Graph />
    </BlockStack>
  );
};

export default Dashboard;