import React from 'react';
import { BlockStack, Box, ContextualSaveBar, Divider, Frame, InlineGrid, Link, Text, useBreakpoints } from '@shopify/polaris';
import { useUpdateSettings } from './api';
import { notEmpty, useField, useForm } from '@shopify/react-form';
import Email from './Email';
import Name from './Name';
import Business from './Business';
import Account from './Account';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import * as styles from "./index.module.scss";
import { useAxiosInstance } from '../../context/AxiosContext';


const Body = ({ name, company_name, business_profile, url, paypal_email, country, email, referralPlans }) => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateSettings({
    axiosInstance,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["settings"] });
    }
  });
  const { smUp } = useBreakpoints();
  const { fields, submit, dirty, reset } = useForm({
    fields: {
      first_name: useField({
        value: name.split(" ")[0],
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: name.split(" ")[1] || "",
        validates: [notEmpty("Last name is required")],
      }),
      company_name: useField({
        value: company_name,
        validates: [notEmpty("Business name is required")],
      }),
      business_profile: useField({
        value: business_profile,
        validates: [notEmpty("Business type is required")],
      }),
      url: useField({
        value: url,
        validates: [notEmpty("Website is required")],
      }),
      paypal_email: useField({
        value: paypal_email || "",
        validates: [
          notEmpty("Email is required"),
          value => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) return 'Please enter a valid email address';
          }
        ],
      }),
      country: useField(country || undefined),
    },
    onSubmit: async (form) => {
      mutate(form);
      return { status: "success" };
    },
  });

  // Render UI for information for individual segments
  const infoSection = (title, description, url = null, urlDescription = '') => {
    return (
      <Box
        as="section"
      >
        <BlockStack gap="200">
          <Text as="h3" variant="headingSm">
            {title}
          </Text>
          <Text as="p" variant="bodyMd" tone="subdued">
            {description} {' '}
            {url ?
              (<Link target="_blank" url={url}>
                {urlDescription}
              </Link>)
              : null
            }
          </Text>
        </BlockStack>
      </Box>
    );
  };
  return (
    <>
      {
        dirty &&
        <div
          className={styles.frameHeight}
        >
          <Frame>
            <ContextualSaveBar
              alignContentFlush
              message="Unsaved changes"
              saveAction={{
                onAction: () => submit(),
                loading: isLoading,
                disabled: isLoading,
              }}
              discardAction={{
                onAction: () => reset(),
              }}
            />
          </Frame>
        </div>
      }
      <InlineGrid columns={{ xs: "1fr", md: "3fr 5fr" }} gap={smUp ? "800" : "400"}>
        {infoSection(
          'Login details',
          'Your email'
        )}
        <Email email={email} />
      </InlineGrid>
      <Divider />
      <InlineGrid columns={{ xs: "1fr", md: "3fr 5fr" }} gap={smUp ? "800" : "400"}>
        {infoSection(
          'Profile details',
          'Your profile details'
        )}
        <Name fields={fields} />
      </InlineGrid>
      <Divider />
      <InlineGrid columns={{ xs: "1fr", md: "3fr 5fr" }} gap={smUp ? "800" : "400"}>
        {infoSection(
          'Business details',
          'Your business details'
        )}
        <Business fields={fields} referralPlans={referralPlans} />
      </InlineGrid>
      <Divider />
      <InlineGrid columns={{ xs: "1fr", md: "3fr 5fr" }} gap={smUp ? "800" : "400"}>
        {infoSection(
          'Account settings',
          'Your account settings'
        )}
        <Account fields={fields} />
      </InlineGrid>
      <Divider />
    </>
  );
};

Body.propTypes = {
  name: PropTypes.any,
  company_name: PropTypes.any,
  business_profile: PropTypes.any,
  url: PropTypes.any,
  paypal_email: PropTypes.any,
  country: PropTypes.any,
  email: PropTypes.any,
  affiliate_fee: PropTypes.any,
  referralPlans: PropTypes.any,
};

export default Body;