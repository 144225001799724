
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
//Render component with data
const node = document.getElementById('AppRoot');
if (node) {
  const root = createRoot(node);
  const data = JSON.parse(node.getAttribute('data'));
  root.render(<App {...data} />);
}
