/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Popover, ActionList, InlineStack, Text, Icon } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  HomeIcon,
  TeamIcon,
  ChatReferralIcon,
  AffiliateIcon,
  ReceiptDollarIcon,
  SettingsIcon,
  QuestionCircleIcon,
  LockIcon,
} from '@shopify/polaris-icons';

const routes = [
  {
    id: 1,
    url: 'dashboard',
    label: 'Dashboard',
    icon: HomeIcon,
  },
  {
    id: 2,
    url: 'visitors',
    label: 'Visitors',
    icon: TeamIcon,
  },
  {
    id: 3,
    url: 'referrals',
    label: 'Referrals',
    icon: ChatReferralIcon,
  },
  {
    id: 4,
    url: 'affiliate-links',
    label: 'Affiliate Links',
    icon: AffiliateIcon,
  },
  {
    id: 5,
    url: 'payouts',
    label: 'Payouts',
    icon: ReceiptDollarIcon,
  },
  {
    id: 6,
    url: 'settings',
    label: 'Settings',
    icon: SettingsIcon,
  },
  {
    id: 7,
    url: 'help',
    label: 'Help',
    icon: QuestionCircleIcon,
  },
];

const freeRoutes = ["dashboard", "settings", "help"];

const MobileMenu = ({ activator, active, toggleActive, adminReview }) => {
  const navigate = useNavigate();

  const handleClick = (url, isRestricted) => {
    if (isRestricted) return; // Do nothing if the route is restricted
    toggleActive();
    navigate(url);
  };

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={() => toggleActive}
      autofocusTarget="first-node"
    >
      <ActionList
        actionRole="menuitem"
        items={routes.map((route) => {
          const isRestricted = !adminReview && !freeRoutes.includes(route.url);

          return {
            content: <InlineStack
              align="space-between"
              gap={200}
            >
              <Text>{route.label}</Text>
              {
                isRestricted &&
                <div>
                  <Icon
                    source={LockIcon}
                  />
                </div>
              }
            </InlineStack>,
            onAction: () => handleClick(route.url, isRestricted),
            icon: route.icon,
          };
        })}
      />
    </Popover>
  );
};

MobileMenu.propTypes = {
  activator: PropTypes.any,
  active: PropTypes.any,
  toggleActive: PropTypes.func.isRequired,
  adminReview: PropTypes.bool, // New prop
};

export default MobileMenu;
