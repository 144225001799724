/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { BlockStack, Card, Collapsible, Text, Divider, Icon, InlineStack, Button } from '@shopify/polaris';
import {
  CaretUpIcon, ChatIcon, ChevronDownIcon
} from '@shopify/polaris-icons';
import PageHeader from '../page_header';
import * as styles from './index.module.scss';
import { handleOpenSupportChat } from '../../../utils';

const Contact = () => {
  return (
    <Card>
      <BlockStack gap={200}>
        <Text fontWeight="bold">Contact us</Text>
        <Text>Our support team is ready to help anytime.</Text>
        <div>
          <Button
            icon={ChatIcon}
            borderWidth="200"
            onClick={() => {
              handleOpenSupportChat();
            }}
          >
            Chat with us
          </Button>
        </div>
      </BlockStack>
    </Card>
  );
};
const Help = () => {
  // Manage which question is open
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const toggleOpen1 = useCallback(() => setOpen1((open) => !open), []);
  const toggleOpen2 = useCallback(() => setOpen2((open) => !open), []);
  const toggleOpen3 = useCallback(() => setOpen3((open) => !open), []);
  const toggleOpen4 = useCallback(() => setOpen4((open) => !open), []);
  const toggleOpen5 = useCallback(() => setOpen5((open) => !open), []);

  // Helper function to toggle background color
  const getBackgroundColor = (isOpen) => (isOpen ? '#FAFAFA' : 'transparent');

  return (
    <BlockStack gap={400}>
      <PageHeader title="Help" />
      <Card padding={0}>
        <BlockStack>
          <div
            className={styles.container}
            style={{ backgroundColor: getBackgroundColor(open1) }}
            onClick={toggleOpen1}
          >
            <InlineStack align="space-between">
              <div>
                How long do commissions last for?
              </div>
              <div><Icon source={open1 ? CaretUpIcon : ChevronDownIcon} /></div>
            </InlineStack>
            <Collapsible open={open1} id="faq1" transition={{ duration: '500ms' }}>
              <div className={styles.answer}><Text variation="subdued">Commissions last for the lifetime of the account</Text></div>
            </Collapsible>
          </div>
          <Divider />

          <div
            className={styles.container}
            style={{ backgroundColor: getBackgroundColor(open2) }}
            onClick={toggleOpen2}
          >
            <InlineStack align="space-between">
              <div>
                How do I get paid?
              </div>
              <div><Icon source={open2 ? CaretUpIcon : ChevronDownIcon} /></div>
            </InlineStack>
            <Collapsible open={open2} id="faq2" transition={{ duration: '500ms' }}>
              <div className={styles.answer}>
                <Text variation="subdued">At the end of each month, your commissions are calculated and available for cash-out via PayPal</Text>
              </div>
            </Collapsible>
          </div>
          <Divider />

          <div
            className={styles.container}
            style={{ backgroundColor: getBackgroundColor(open3) }}
            onClick={toggleOpen3}
          >
            <InlineStack align="space-between">
              <div>
                When do I get paid?
              </div>
              <div><Icon source={open3 ? CaretUpIcon : ChevronDownIcon} /></div>
            </InlineStack>
            <Collapsible open={open3} id="faq3" transition={{ duration: '500ms' }}>
              <div className={styles.answer}>
                <Text variation="subdued">Payments are verified and paid-out the month after your commission is earned. For example, any commissions earned in February will be available March 16th</Text>
              </div>
            </Collapsible>
          </div>
          <Divider />

          <div
            className={styles.container}
            style={{ backgroundColor: getBackgroundColor(open4) }}
            onClick={toggleOpen4}
          >
            <InlineStack align="space-between">
              <div>
                How long do cookies last for?
              </div>
              <div><Icon source={open4 ? CaretUpIcon : ChevronDownIcon} /></div>
            </InlineStack>
            <Collapsible open={open4} id="faq4" transition={{ duration: '500ms' }}>
              <div className={styles.answer}>
                <Text variation="subdued">We use Cookies to track your referrals. The cookies last for 90 days after someone clicks on your link. If they click again, the 90 days starts again.</Text>
              </div>
            </Collapsible>
          </div>
          <Divider />

          <div
            className={styles.container}
            style={{ backgroundColor: getBackgroundColor(open5) }}
            onClick={toggleOpen5}
          >
            <InlineStack align="space-between">
              <div>
                What is the minimum amount I can withdraw?
              </div>
              <div><Icon source={open5 ? CaretUpIcon : ChevronDownIcon} /></div>
            </InlineStack>
            <Collapsible open={open5} id="faq5" transition={{ duration: '500ms' }}>
              <div className={styles.answer}>
                <Text variation="subdued">The minimum withdrawal amount is $25</Text>
              </div>
            </Collapsible>
          </div>
        </BlockStack>
      </Card>
      <Contact />
    </BlockStack>
  );
};

export default Help;
