/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, DataTable, Icon, InlineStack, Text, Tooltip, useBreakpoints } from "@shopify/polaris";
import { DuplicateIcon } from "@shopify/polaris-icons";
import React from "react";
import * as styles from "./index.module.scss";
import PropTypes from "prop-types";

const AFFILIATE_LINK_PREFIX = "https://partners.starapps.studio/";

const copyToClipboard = (link) => {
  navigator.clipboard.writeText(link)
    .then(() => {
      console.log('Link copied to clipboard:', link);
      // Optionally show a success message here
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
};

function extractAppName(shortName) {
  const appNames = {
    "all_apps": "All apps",
    "via": "StarApps Variant Image",
    "vsk": "Swatch King: Variant options",
    "vdk": "Variant Descriptions King",
    "asap": "Urgency King: Low Stock Alert"
  };

  return appNames[shortName] || "App";
}

export default function Table({ affiliate }) {
  const { smDown } = useBreakpoints();
  let rows = [];
  if (affiliate?.visits && typeof affiliate.visits === 'object') {
    Object.keys(affiliate.visits).forEach(key => {
      const visit = affiliate.visits[key];
      if (visit && Array.isArray(visit)) {
        rows.push([extractAppName(key),
        <InlineStack gap={400} key={key}>
          <Text>{smDown ? visit[0].split(AFFILIATE_LINK_PREFIX)[1] : visit[0]}</Text>
          <div
            onClick={() => copyToClipboard(visit[0])}
            className={styles.pointer}
          >
            <Tooltip content="Copy">
              <Icon
                source={DuplicateIcon}
                tone="subdued"
                accessibilityLabel="Copy link"
              />
            </Tooltip>
          </div>
        </InlineStack>
          , visit[1]]);
      }
    });
  }

  return (
    <Card
      padding={0}
    >
      <div className="table">
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'numeric',
          ]}
          headings={[]}
          totals={[
            <Text fontWeight="bold" key={1}>App</Text>,
            <Text fontWeight="bold" key={2}>Affiliate link</Text>,
            <Text fontWeight="bold" key={3}>Views</Text>,
          ]}
          totalsName={{
            singular: "App",
            plural: "App"
          }}
          footerContent={rows.length === 0 ? <Text>Your affiliate links will be displayed here</Text> : null} // Optional footer message
          rows={rows}
        />
      </div>
    </Card>
  );
}

Table.propTypes = {
  affiliate: PropTypes.any,
};