import React from "react";
import { InlineGrid, useBreakpoints } from "@shopify/polaris";
import Sidebar from "./Sidebar";
import NewUserForm from "./NewUserForm";
import * as layoutStyles from '../layout/index.module.scss';
import * as styles from './index.module.scss';

const Body = () => {
  const { smUp } = useBreakpoints();
  return (
    <div
      className={styles.pageHeight}
    >
      <InlineGrid columns={smUp ? 2 : 1} gap={smUp ? 400 : 0}>
        <div className={styles.mobilePage}>
          <Sidebar />
        </div>
        <NewUserForm />
      </InlineGrid>
    </div>
  );
};

const NewUserPage = () => {
  const { smUp } = useBreakpoints();
  return (
    <div className={layoutStyles.top}>
      <header
        className={layoutStyles.header}
      >
        <img
          src="https://cdn.starapps.studio/v2/apps/vsk/starapps/swatches/Print/logov2.png"
          alt="Header Logo"
          className={styles.images}
        />
      </header>
      {
        smUp ?
          <div className={styles.page}>
            <Body />
          </div> :
          <Body />
      }
    </div>
  );
};

export default NewUserPage;
